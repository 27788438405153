import state from '@/header/store/state';
import axios from 'axios';


const baseUrl = state.translations.searchScriptUrl;

const actions = {
  setQueryString(store: any, queryString: string) {
    store.commit("SET_QUERY_STRING", queryString);
  },
  setLastQueryStrings(store: any, lastQueryStrings: Array<string>) {
    store.commit("SET_LAST_QUERY_STRINGS", lastQueryStrings);
  },
  async initialize(store: any) {
    let url = baseUrl + "?&q=" + state.queryString
    await axios.get(url)
      .then(response => {
        store.commit({
          type: "SET_PRODUCTS", 
          config: response.data.config,
          products: response.data.products
        }) 
        store.commit({
          type: "SET_GROUPS", 
          config: response.data.config.groups, 
          results: response.data.groups 
        })
        store.commit({
          type: "SET_SUGGESTIONS", 
          results: response.data.suggest 
        })
        store.commit({
          type: "SET_AUTOCOMPLETE_ENTRIES", 
          results: response.data.autocomplete 
        })
      }
    )
  },
  resetSearch(store: any) {
    store.commit("RESET_SEARCH")
  }
};

export default actions;
