interface LastQueryStrings {
  queryStrings: Array<string>,
  timestamp: number,
};

const mieleGlobalsGeneralCountry = mieleGlobals['general.country'];
const mieleGlobalsGeneralLanguage = mieleGlobals['general.language'];
let storageKey = `miele_search_lastquerystrings`;

if(mieleGlobalsGeneralCountry && mieleGlobalsGeneralLanguage) {
  storageKey = `miele_search_lastquerystrings_${mieleGlobalsGeneralCountry}_${mieleGlobalsGeneralLanguage}`;
}

const maxQueryStringsCount = 3;

export const getLastQueryStrings = (): Array<string> => {
    const lastQueryStrings = localStorage.getItem(storageKey);
  
    if (!lastQueryStrings) {
      return [];
    }
  
    let parsedLastQueryStrings: LastQueryStrings;

    try {
      parsedLastQueryStrings = JSON.parse(lastQueryStrings);
    } catch (error) {
      return [];
    }
  
    if (!parsedLastQueryStrings) {
      return [];
    }

    const currentTime = Date.now();
    const dayDiff = (currentTime - parsedLastQueryStrings.timestamp) / (24 * 60 * 60 * 1000);
    const maxDaysCount = 7;

    if (dayDiff > maxDaysCount) {
      localStorage.removeItem(storageKey);

      return [];
    }
  
    if (parsedLastQueryStrings.queryStrings.length > maxQueryStringsCount) {
      parsedLastQueryStrings.queryStrings.length = maxQueryStringsCount;
    }

    return parsedLastQueryStrings.queryStrings;
};

export const setLastQueryStrings = (queryStrings: Array<string>) => {
  const lastQueryStrings: LastQueryStrings = {
    queryStrings,
    timestamp: Date.now(),
  };

  if (lastQueryStrings.queryStrings.length > maxQueryStringsCount) {
    lastQueryStrings.queryStrings.length = maxQueryStringsCount;
  }

  localStorage.setItem(storageKey, JSON.stringify(lastQueryStrings));
}
