import State from '@/header/models/state.model';

const getters = {
  getTranslations(state: State) {
    return state.translations;
  },
  getQueryString(state: State) {
    return state.queryString;
  },
  getLastQueryStrings(state: State) {
    return state.lastQueryStrings;
  },
  getProductResults(state: State) {
    return state.productGroup.results;
  },
  getProductTotalResults(state: State) {
    return state.productGroup.totalResults;
  },
  getTotalResults(state: State) {
    return state.totalResults;
  },
  getGroups(state: State) {
    return state.groups;
  },
  getSuggestions(state: State) {
    return state.suggestions;
  },
  getAutocompleteEntries(state: State) {
    return state.autocompleteEntries;
  }
};

export default getters;
