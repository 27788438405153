

























































































































import { mapGetters } from 'vuex';

export default {
    name: "SearchFlyout",
    computed: mapGetters(['getTranslations', 'getQueryString', 'getLastQueryStrings', 'getProductResults', 'getTotalResults', 'getProductTotalResults', 'getGroups', 'getSuggestions', 'getAutocompleteEntries']),
    
}
