import State from '@/header/models/state.model';

const mutationUtils = {

};

const mutations = {
  SET_QUERY_STRING(state: State, queryString: string) {
    state.queryString = queryString;
  },
  SET_LAST_QUERY_STRINGS(state: State, lastQueryStrings: Array<string>) {    
    state.lastQueryStrings = lastQueryStrings;
  },
  RESET_SEARCH(state: State) {
    state.queryString = ""
    state.totalResults = 0
    state.productGroup = {
      totalResults: 0,
      results: []
    },
    state.groups = [],
    state.suggestions = []
    state.autocompleteEntries = []
  },
  SET_PRODUCTS(state, payload) {
    let config = payload.config
    let products = payload.products.grouped.ot_matNo_str.groups
    state.productGroup.results = []
    state.productGroup.totalResults = payload.products.grouped.ot_matNo_str.matches
    for (let p in products) {
      state.productGroup.results.push(
        {
          "matNo": products[p].doclist.docs[0].ot_matNo_str,
          "design": products[p].doclist.docs[0].ot_designType_str,
          "shortPos": products[p].doclist.docs[0].ot_shortPos_str,
          "label": products[p].doclist.docs[0].ot_productLabel_str,
          "img": products[p].doclist.docs[0].ot_img30Z04_str,
          "url": config.dsPath + products[p].doclist.docs[0].ot_filename_str,
          "hasProductdetails": products[p].doclist.docs[0].ot_hasProductdetails_str,
          "hasDownloads": products[p].doclist.docs[0].ot_hasDownloads_str
        }
      )

    }
  },
  SET_GROUPS(state, payload) {
    let config = payload.config
    let results = payload.results
    let groupResults: any[] = []
    let groupNumberFound: number = 0
    let projectResults: any[]
    let web20TotalResults: number = 0
    let web20ResultsLoaded: number = 0
    state.groups = []
    for (let group in config) {
      let currentGroup = results.find(element => element.id === config[group].id)
      groupResults = []
      groupNumberFound = 0
      if (currentGroup) {
        projectResults = currentGroup.web20.response.docs
        web20TotalResults = currentGroup.web20.response.numFound
        web20ResultsLoaded = projectResults.length
        groupNumberFound += currentGroup.web20.response.numFound
        if (projectResults.length > 0) {
          for (let doc in projectResults) {
            groupResults.push(
              {
                "id": "web20" + projectResults[doc].CIS_ID,
                "headline": projectResults[doc].title,
                "description": "",
                "url": projectResults[doc].ot_pageURL_str,
                "score": projectResults[doc].score
              }
            )
          }
        }
      }
      state.groups.push(
        {
          "id": config[group].id,
          "name": config[group].name,
          "detailView": false,
          "web20TotalResults": web20TotalResults,
          "web20ResultsLoaded": web20ResultsLoaded,
          "totalResults": groupNumberFound,
          "results": groupResults.sort((a, b) => -1 * (a.score - b.score))
        }
      )
    }
  },
  SET_SUGGESTIONS(state, payload) {
    let results = payload.results.suggest.mieleSuggester
    let s = Object.keys(results)
    let suggestions = results[s[0]].suggestions
    state.suggestions = []
    for (let suggestion in suggestions) {
      state.suggestions.push( 
        {
          "term": suggestions[suggestion].term
        }
      )
    }

  },
  SET_AUTOCOMPLETE_ENTRIES(state, payload) {
    let results = payload.results.facet_counts.facet_fields.autocomplete
    state.autocompleteEntries = []
    for (let i = 0; i < results.length; i+=2) {
      state.autocompleteEntries.push( 
        {
          "term": results[i],
          "count": parseInt(results[i+1],10)
        }
      )
    }

  },
};

export default mutations;
