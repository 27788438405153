import State from '@/header/models/state.model';
import HeaderSearchAppData from '@/header/utility/header-search-app-data';

if (!HeaderSearchAppData) {
  throw new TypeError("Onsite search app data is not available");
}

const { translations } = HeaderSearchAppData;

/*
if (!config) {
  throw new TypeError("Config object is not available");
}
*/

if (!translations) {
  throw new TypeError("Translation object is not available");
}

// Set initial state
const state: State = {
  // config,
  translations,
  queryString: "",
  totalResults: 0,
  productGroup: {
    totalResults: 0,
    results: []
  },
  groups: [],
  suggestions: [],
  autocompleteEntries: [],
  lastQueryStrings: [],
};

export default state;
