






































import SearchFlyout from "./SearchFlyout.vue";
import { mapGetters, mapActions } from 'vuex';
import { getLastQueryStrings } from '@/shared/utility/last-query-strings';

export default {
  name: "HeaderSearch",
  components: { SearchFlyout },
  data() {
    return {
      query: ''
    }
  },
  mounted() {
    const flyout = document.getElementById('header-search-flyout') as HTMLElement & { instance: any };
    flyout?.addEventListener('searchFlyoutChange', ({ detail }: any) => {
        if (detail.open) {
            setTimeout(() => {
                this.$store.dispatch('setLastQueryStrings', getLastQueryStrings());
                flyout.instance.openFlyout();
            }, 200);
        }
    });
  },
  computed: mapGetters(['getTranslations', 'getQueryString']),
  methods: {
    ...mapActions(['initialize', 'resetSearch']),
    runSearch() {
      this.$store.dispatch("setQueryString", this.query)
      if (this.query !== "" && this.query.length > 1) {
        this.initialize()
      } else {
        this.resetSearch()
      }
    },
    gotoOnsiteSearch() {
      if (this.query !== "") {
        window.location.href = this.getTranslations.onsiteSearchUrl + "?s=" + this.query
      }
    },
    searchFlyoutChange: function () {
    }
  },
};
