import Vue from 'vue'
import HeaderSearchApp from './HeaderSearchApp.vue'
import store from "./store";

Vue.config.productionTip = false
Vue.config.devtools = true;
Vue.prototype.Miele = window.Miele;

if (document.querySelector("#onsite-search-header-app")) {
  new Vue({
    store: store,
    render: h => h(HeaderSearchApp),
  }).$mount('#onsite-search-header-app')
}