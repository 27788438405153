




import HeaderSearch from './components/HeaderSearch.vue'

export default {
  name: 'HeaderSearchApp',
  components: {
    HeaderSearch
  }
}
